import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Section from "../../components/Section"
import { Body, Header2 } from "../../components/Typography"

import { GatsbyImage } from "gatsby-plugin-image"

import styled from "styled-components"
import { FiArrowRight, FiArrowLeft } from "react-icons/fi"
import Button from "../../components/Button"

const ProfileGrid = styled.div`
  display: grid;
  gap: 32px;
  padding-top: 32px;
  max-width: 816px;
  align-items: start;
  margin: 0 auto;

  grid-template-columns: 1fr 2fr;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`
const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 32px;
  max-width: 816px;
  margin: 0 auto;
`

function ArniePage({ data }) {
  return (
    <Layout pageTitle="About">
      <Section>
        <ButtonRow>
          <Button url="/about/bob">
            <FiArrowLeft /> Previous Profile
          </Button>
          <Button url="/about/daniel">
            Next Profile <FiArrowRight />
          </Button>
        </ButtonRow>
        <ProfileGrid>
          <GatsbyImage
            image={data.arnie.nodes[0].childImageSharp.gatsbyImageData}
          />
          <div>
            <Header2>Arnie Papp</Header2>
            <br />
            <Body>
              Arnie is a serial entrepreneur with experience in the full startup
              lifecycle from pre-funding to post-IPO. Prior to co-founding
              CueBack, Arnie was a member of the senior management team at
              Calypso Technology that grew the company from 20 to 500+ employees
              and global revenue from $2 million to $220 million - with no
              external funding or debt. Calypso is now a multi-billion dollar
              company.
              <br />
              <br />
              Before Calypso he was VP Sales at GlobalSight Corporation, a
              content globalization software startup he co-funded with DFJ, a
              top-tier Silicon Valley VC firm. From 1992 to 1997 Arnie was a
              member of the senior management team at Infinity Financial
              Technology from pre-funding (Sequoia) to post-IPO (NASDAQ: Goldman
              Sachs Lead).
              <br />
              <br />
              Arnie lived in Japan from 1986 to 1997 and speaks fluent Japanese.
              Arnie holds a Bachelor degree in Business and a Masters degree in
              Japanese Studies from the University of Western Australia. He is
              also an active Angel investor with pre-IPO investments in
              Facebook, Arista Networks, RingCentral and many others.
              <br />
              <br />
              In a prior life, he backpacked around the world by himself for 2
              1/2 years.
            </Body>
          </div>
        </ProfileGrid>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    arnie: allFile(filter: { relativePath: { eq: "arnie.jpeg" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
  }
`

export default ArniePage
